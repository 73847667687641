import React, { Component } from 'react';
import { Col, Container, Form, FloatingLabel, Row, Button } from "react-bootstrap";
import FileUploader from './FileUploader';
import WordDocumentModifier from './WordDocumentModifier';


class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null,
        };
    }

    handleFileChange = (event) => {
        this.setState({ selectedFile: event.target.files[0] });
        this.handleUpload()
    };

    handleUpload = () => {
        const { selectedFile } = this.state;
        if (selectedFile) {
            // Perform upload logic here, such as sending the file to a server or processing it locally
            console.log('Uploading file:', selectedFile.name);
        } else {
            console.log('No file selected.');
        }
    };

    // Function to download the file
    downloadFile = (file, fileName) => {
        const element = document.createElement("a");
        const fileBlob = new Blob([file], { type: 'text/plain' });
        element.href = URL.createObjectURL(fileBlob);
        element.download = fileName;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        document.body.removeChild(element); // Clean up the element
    };


    openFile = () => {
        const { selectedFile } = this.state;
        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const fileContent = e.target.result;
                console.log('File content:', fileContent);
                this.downloadFile(fileContent, selectedFile.name);
            };
            reader.readAsText(selectedFile);
        } else {
            console.log('No file selected.');
        }
    };

    render() {
        return (
            <Container>
                <Row className='mt-3'>
                    <Col>
                        <FloatingLabel controlId="floatingTextarea2" label="论文格式">
                            <Form.Control
                                as="textarea"
                                placeholder="Leave a comment here"
                                style={{ height: '600px' }}
                            />
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    {/* <Button variant="primary" onClick={this.handleUpload}>Upload</Button> */}
                    <input type="file" onChange={this.handleFileChange} />

                    <Button className="mt-5" onClick={this.openFile}>Format</Button>
                </Row>
            </Container>
        );
    }
}

export default Home;

