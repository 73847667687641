
const { Document, AlignmentType } = require('docx');


class WordDocumentModifier {
    constructor(documentPath, requirementsPath) {
        this.documentPath = documentPath;
        this.requirementsPath = requirementsPath;
        const existingTemplate = '/Users/lvhuayu/Desktop/demo.docx';

        this.requirements = null;
    }

    loadRequirements() {
        return fetch(this.requirementsPath)
            .then((response) => response.json())
            .then((data) => {
                this.requirements = data;
            })
            .catch((error) => {
                console.error('Error loading requirements:', error);
            });
    }

    modifyPageSettings() {
        const pageSettings = this.requirements.page_settings;
        const sections = this.doc.sections;
        sections.forEach((section) => {
            section.pageMarginTop = 1440 * pageSettings.top; // 1 inch = 1440 TWIPs
            section.pageMarginBottom = 1440 * pageSettings.bottom;
            section.pageMarginLeft = 1440 * pageSettings.left;
            section.pageMarginRight = 1440 * pageSettings.right;
            section.headerDistance = 1440 * pageSettings.headerDistance;
            section.footerDistance = 1440 * pageSettings.footerDistance;
            section.properties.pageWidth = 12240; // 8.5 inches = 12240 TWIPs
            section.properties.pageHeight = 15840; // 11 inches = 15840 TWIPs
            section.properties.orientation = pageSettings.orientation === 'portrait' ? 'portrait' : 'landscape';
        });
    }

    async modifyFontSettings() {
        await this.loadRequirements();
        const fontSettings = this.requirements.font_settings;
        const { title, document, keyword, keyWords, toc } = fontSettings;

        // Title font settings
        this.doc.addSection({ properties: { titlePage: true } });
        const titleParagraph = this.doc.createParagraph();
        titleParagraph.createTextRun(title.text).bold().size(title.size).font(title.font);
        this.doc.addParagraph(titleParagraph);

        // Document font settings
        const docParagraphs = this.doc.sections[0].properties.children;
        for (let i = 1; i < docParagraphs.length; i++) {
            const paragraph = this.doc.createParagraph();
            paragraph.createTextRun(docParagraphs[i].text).size(document.size).font(document.font);
            this.doc.addParagraph(paragraph);
        }

        // Keyword font settings
        const keywordParagraph = this.doc.createParagraph();
        keywordParagraph.createTextRun(keyword.label).size(keyword.labelSize).font(keyword.labelFont);
        this.doc.addParagraph(keywordParagraph);

        // Key Words font settings
        const keyWordsParagraph = this.doc.createParagraph();
        keyWordsParagraph.createTextRun(keyWords.label).size(keyWords.labelSize).font(keyWords.labelFont);
        this.doc.addParagraph(keyWordsParagraph);

        // TOC font settings
        this.doc.createParagraph().heading1().title().createTextRun('Table of Contents');
        this.doc.createTableOfContents().style('Table of Contents');
        this.doc.styles.findOrCreateParagraphStyle('TOC', 'TOC')
            .next('Normal')
            .size(toc.size)
            .font(toc.font)
            .basedOn('Normal')
            .quickFormat()
            .quickFormatLevel(1, { alignment: AlignmentType.LEFT })
            .quickFormatLevel(2, { alignment: AlignmentType.LEFT });
        this.doc.sections.forEach((section) => {
            section.createParagraph().style('TOC');
        });
    }

    saveModifiedDocument(outputPath) {
        this.doc.save(outputPath);
    }
}

export default WordDocumentModifier;

