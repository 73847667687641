import Container from 'react-bootstrap/Container';
import React, { Component } from "react";
import {Navbar, Nav} from "react-bootstrap";
import {Link} from "react-router-dom";
import logo from '../favicon.ico';

class NavbarComp extends Component {
    render() {
        return (
            <div>
                <Navbar expand="lg" className="bg-body-tertiary">
                    <Container>
                    <Navbar.Brand as={Link} to={"/home"} className="flex items-center">
                        <img src={logo} style={{ width: 35 }} className="mr-2" alt="Logo" />
                        <span className="ml-1">Essay Formatter</span>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                        <Nav.Link as={Link} to={"/home"}>Home</Nav.Link>
                        <Nav.Link as={Link} to={"/chatGpt"}>ChatGpt</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        );
    }
}

export default NavbarComp;