async function callChatGPTAPI(prompt) {
    try {
        const response = await fetch('https://api.openai.com/v1/chat/completions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 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' // Replace with your OpenAI API key
            },
            body: JSON.stringify({
                'model': 'gpt-3.5-turbo',
                'messages': [{'role': 'system', 'content': 'You are a user'}, {'role': 'user', 'content': prompt}],
                'temperature': 1.0
            })
        });

        const data = await response.json();
        // Extract the generated reply from the API response
        const reply = data.choices[0].message.content;
        console.log(reply);
        return reply;
    } catch (error) {
        console.error('Error:', error);
        // Handle error as per your application's requirements
        return '';
    }
}

export { callChatGPTAPI };