import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

class FileUploader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null,
        };
    }

    handleFileChange = (event) => {
        this.setState({ selectedFile: event.target.files[0] });
    };

    handleUpload = () => {
        const { selectedFile } = this.state;
        if (selectedFile) {
            // Perform upload logic here, such as sending the file to a server or processing it locally
            console.log('Uploading file:', selectedFile.name);
        } else {
            console.log('No file selected.');
        }
    };

    render() {
        return (
            <div>

                <Button variant="primary" onClick={this.handleUpload}>Upload</Button>
            </div>
        );
    }
}

export default FileUploader;
