import './App.css';
import NavbarComp from "./components/NavbarComp";
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import Home from "./components/Home";
import ChatGpt from "./components/ChatGpt";
import Footer from "./components/Footer";

const App = () => (
    <div className="App">
        <BrowserRouter>
            <NavbarComp />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="home" element={<Home />} />
                <Route path="chatGpt" element={<ChatGpt />} />
            </Routes>
        </BrowserRouter>
        <footer>
            <Footer />
        </footer>
    </div>
);

export default App;
