import React, {Component} from 'react';

class Footer extends Component {
    render() {
        return (
            <footer className="fixed bottom-0 z-20 footer">
                <span className="text">©2023 Essay Formatter. All rights reserved.</span>
            </footer>
        );
    }
}

export default Footer;